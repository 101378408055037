<template>
  <el-main>
    <el-form class="el-form-search" label-width="110px">
      <el-form-item label="分销员：">
        <el-input size="small" placeholder="请输入昵称" v-model="searchForm.nickname"></el-input>
      </el-form-item>
      <el-form-item label="分销员等级：">
        <el-select v-model="searchForm.level_id" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="item in gradeList" :key="item.level_id" :label="item.name" :value="item.level_id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="提现单号：">
        <el-input size="small" placeholder="请输入提现单号" v-model="searchForm.withdraw_no"></el-input>
      </el-form-item>
      <el-form-item label="手机号：">
        <el-input size="small" placeholder="请输入手机号" v-model="searchForm.mobile"></el-input>
      </el-form-item>
      <el-form-item label="提现时间：">
        <el-date-picker
          @change="getTime"
          v-model="searchForm.time"
          size="small"
          value-format="timestamp"
          type="daterange"
          range-separator="~"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        ></el-date-picker>
      </el-form-item>
      <el-form-item label="结算状态：">
        <el-select v-model="searchForm.status" size="small">
          <el-option label="全部" value=""></el-option>
          <el-option label="已驳回" :value="1"></el-option>
          <el-option label="待审核" :value="2"></el-option>
          <el-option label="已通过" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label=" " label-width="20px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="withdraw_no" label="提现单号" align="center"></el-table-column>
      <el-table-column prop="nickname" label="分销员昵称" align="center"></el-table-column>
      <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
      <el-table-column prop="level_name" label="分销员等级" align="center"></el-table-column>
      <el-table-column prop="withdrawal_amount" label="提现金额" align="center"></el-table-column>
      <el-table-column prop="date" label="提现时间" align="center"></el-table-column>
      <el-table-column prop="type" label="打款类型" align="center">
        <template v-slot="{ row }">
          {{ row.type == 1 ? '银行卡' : '微信钱包' }}
        </template>
      </el-table-column>
      <el-table-column prop="comefrom_icon" label="来源" align="center">
        <template v-slot="{ row }">
          <el-image :src="row.comefrom_icon"></el-image>  
        </template>
      </el-table-column>
      <el-table-column prop="status" label="结算状态" align="center">
        <template v-slot="{ row }">
          {{ row.status == 0 ? '已驳回' : row.status == 1 ? '待审核' : '已通过' }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="240" align="center" fixed="right">
        <template v-slot="{ row }">
          <el-button v-if="row.status == 1" @click="audit(row)" type="text" size="small">审核</el-button>
          <el-button v-if="row.status != 1" @click="(auditInfo = row), (disloglog = !0)" type="text" size="small">记录</el-button>
          <el-button
            @click="
              $router.push({
                path: '/extension/distribution/clearing',
                query: { nickname: row.nickname },
              })
            "
            type="text"
            size="small"
          >
            明细
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    <el-dialog title="审核" :visible.sync="dislogaudit" width="600px">
      <el-form class="auditForm" label-width="110px">
        <el-form-item label="审核结果：">
          <el-radio-group v-model="status">
            <el-radio :label="2">通过审核并打款</el-radio>
            <el-radio :label="-1">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
        <template v-if="status == 2">
          <el-form-item label="分销员昵称：">
            {{ auditInfo.nickname }}
          </el-form-item>
          <el-form-item label="打款类型：">
            {{ auditInfo.type == 1 ? '银行卡' : '微信钱包' }}
          </el-form-item>
        </template>
        <template v-if="auditInfo.type == 1 && status == 2">
          <el-form-item label="开户行：">
            {{ auditInfo.bank_info }}
          </el-form-item>
          <el-form-item label="银行卡号：">
            {{ auditInfo.bank_card }}
          </el-form-item>
          <el-form-item style="margin-bottom: 20px" label="打款金额：">
            <el-input v-model="remit_amount"></el-input>
          </el-form-item>
          <el-form-item style="margin-bottom: 20px" label="打款凭证：">
            <ReadyUploadSource
              :showStyle="{
                width: '100px',
                height: '100px',
              }"
              type="image"
              :path="voucher"
              :isNeedHiddenUp="true"
              @removeThis="() => (voucher = '')"
              @getSource="val => (voucher = val.path)"
            ></ReadyUploadSource>
          </el-form-item>
        </template>
        <el-form-item v-if="auditInfo.type == 1 || status == -1" label="备注：">
          <el-input v-model="remark" type="textarea" :rows="3"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dislogaudit = !1">取 消</el-button>
        <el-button type="primary" @click="handleAudit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="记录" :visible.sync="disloglog" width="600px">
      <el-form class="auditForm" label-width="110px">
        <el-form-item label="审核结果：">
          {{ auditInfo.status ? '通过审核并打款' : '驳回' }}
        </el-form-item>
        <template v-if="status == 2">
          <el-form-item label="分销员昵称：">
            {{ auditInfo.nickname }}
          </el-form-item>
          <el-form-item label="打款类型：">
            {{ auditInfo.type == 1 ? '银行卡' : '微信钱包' }}
          </el-form-item>
        </template>
        <template v-if="auditInfo.type == 1 && status == 2">
          <el-form-item label="开户行：">
            {{ auditInfo.bank_info }}
          </el-form-item>
          <el-form-item label="银行卡号：">
            {{ auditInfo.bank_card }}
          </el-form-item>
          <el-form-item label="打款金额：">
            {{ auditInfo.remit_amount }}
          </el-form-item>
          <el-form-item label="打款凭证：">
            <div class="voucher">
              <el-image :src="auditInfo.voucher" :preview-src-list="[auditInfo.voucher]"></el-image>
              <el-button type="text">点击查看</el-button>
            </div>
          </el-form-item>
        </template>
        <el-form-item v-if="auditInfo.status == 0" label="驳回原因：">
          {{ auditInfo.remark }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-main>
</template>

<script>
import { getDateformat } from '@/util/getDate';
import Paging from '@/components/paging';
import ReadyUploadSource from '@/components/readyUploadSource';
import wx from '@/assets/image/wx.png';
import ali from '@/assets/image/ali.png';
import douyin from '@/assets/image/douyin.png';
import baidu from '@/assets/image/baidu.png';
import qq from '@/assets/image/qq.png';
export default {
  components: {
    Paging,
    ReadyUploadSource,
  },
  data() {
    return {
      status: 2,
      voucher: '',
      remark: '',
      remit_amount: '',
      dislogaudit: !1,
      disloglog: !1,
      date: '',
      list: [],
      gradeList: [],
      total_number: 0,
      searchForm: {
        page: 1,
        rows: 10,
        mobile: '',
        nickname: '',
        level_id: '',
        withdraw_no: '',
        start_time: '',
        end_time: '',
        status: '',
        time: '',
      },
      auditInfo: {},
    };
  },
  created() {
    this.getList();
    this.getGrade();
  },
  methods: {
    audit(row) {
      this.auditInfo = row;
      this.status = 2;
      this.remark = '';
      this.remit_amount = '';
      this.voucher = '';
      this.dislogaudit = !0;
    },
    getTime(val) {
      if (val) {
        this.searchForm.start_time = val[0] / 1000;
        this.searchForm.end_time = val[1] / 1000;
      }
    },
    cancelSearch() {
      this.searchForm = {
        page: 1,
        rows: 10,
        mobile: '',
        nickname: '',
        level_id: '',
        withdraw_no: '',
        start_time: '',
        end_time: '',
        status: '',
        time: '',
      };
      this.getList();
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
        this.getList();
      } else {
        this.searchForm.page = val;
        this.getList();
      }
    },
    handleAudit() {
      if(this.remit_amount > this.auditInfo.withdrawal_amount) return this.$message.warning("打款金额不能大于申请提现金额！");
      this.$axios
        .post(this.$api.distribution.withdrawAudit, {
          id: this.auditInfo.id,
          status: this.status,
          remark: this.remark,
          remit_amount: this.remit_amount,
          voucher: this.voucher,
        })
        .then(res => {
          if (res.code == 0) {
            this.dislogaudit = !1;
            this.getList();
            this.$message.success('处理成功');
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getGrade() {
      this.$axios.post(this.$api.distribution.levelList2, {}).then(res => {
        if (res.code == 0) {
          this.gradeList = res.result;
        }
      });
    },
    getList() {
      let data = Object.assign({}, this.searchForm);
      delete data.time;
      this.$axios.post(this.$api.distribution.withdrawList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          list.map(item => {
            item.date = getDateformat(item.create_time);
            if (item.comefrom == 1) {
              item.comefrom_icon = wx;
            }
            if (item.comefrom == 2) {
              item.comefrom_icon = ali;
            }
            if (item.comefrom == 4) {
              item.comefrom_icon = douyin;
            }
            if (item.comefrom == 5) {
              item.comefrom_icon = qq;
            }
            if (item.comefrom == 6) {
              item.comefrom_icon = baidu;
            }
          });
          this.list = list;
          this.total_number = res.result.total_number;
        } else {
          this.$message.warning(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
}
.auditForm .el-form-item {
  margin-bottom: 5px;
}
.voucher {
  position: relative;
  .el-image {
    opacity: 0;
    position: absolute;
    width: 58px;
    height: 40px;
    top: 0;
    left: 0;
    z-index: 10;
  }
}
</style>
